<template>
  <div>
    <vuetify-money
    class="text-right"
    :label="label"
       outlined rounded dense 
       :hideDetails="!isDetail"
       v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
      v-bind:options="options"
      ref="myInput" :value="value"
      v-model="inputVal"
      :id="name"
      :name="name" :rules="formRules"
      v-bind:properties="properties"
    />
  </div>
</template>
<script>
export default {
  data: () => ({
    setValue: "0",
    placeholder: " ",
    readonly: false,
    disabled: false,
    outlined: true,
    clearable: true,
    formRules: [],
    valueWhenIsEmpty: "",
    options: {
      locale: "pt-BR",
      prefix: " ",
      suffix: " ",
      length: 20,
      precision: 0
    },
    properties: {
    },
  }),
   methods: {
            setFormRules() {
                let validate = this.validation; 
                let tmp_validate = [];   
                if (validate) {
                    validate.forEach(rule => {
                      switch (rule) {
                          case 'required':
                              tmp_validate.push(v => !!v || 'Tidak Boleh Kosong');
                          break;
                      }
                  });
                }             
              

                if (this.max) {
                    tmp_validate.push(v => v.length <= this.max || "Maksimal "+this.max+" Karakter");
                }
                if (this.min) {
                    tmp_validate.push(v => v.length >= this.min || "Minimal "+this.min+" Karakter");
                }

                 this.formRules = tmp_validate;
            },
            handleInput (e) {
              // console.log(this.$refs);
              // console.log(e);

              // this.$emit("update:value", this.$refs.myInput.)
                this.$emit('input', this.$refs.myInput.value)
            }
        },
        mounted() {
           this.setFormRules();
        },
        computed: {
          inputVal: {
            get() {
              return this.value;
            },
            
            set(val) {
              this.$emit('input', val);
            }
          }
        },
        props: ['label', 'name', 'value','validation','max','min','isDetail'],
        name: 'formInput',
};
</script>